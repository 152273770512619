// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-team-member-team-member-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/team-member/team-member.js" /* webpackChunkName: "component---src-templates-team-member-team-member-js" */),
  "component---src-templates-ideas-archive-ideas-archive-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/ideas-archive/ideas-archive.js" /* webpackChunkName: "component---src-templates-ideas-archive-ideas-archive-js" */),
  "component---src-templates-work-archive-work-archive-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/work-archive/work-archive.js" /* webpackChunkName: "component---src-templates-work-archive-work-archive-js" */),
  "component---src-templates-offering-offering-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/offering/offering.js" /* webpackChunkName: "component---src-templates-offering-offering-js" */),
  "component---src-pages-404-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-ideas-stories-ideas-stories-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/ideas-stories/ideas-stories.js" /* webpackChunkName: "component---src-templates-ideas-stories-ideas-stories-js" */),
  "component---src-templates-contact-contact-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/contact/contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-ideas-tutorials-ideas-tutorials-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/ideas-tutorials/ideas-tutorials.js" /* webpackChunkName: "component---src-templates-ideas-tutorials-ideas-tutorials-js" */),
  "component---src-templates-about-about-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/about/about.js" /* webpackChunkName: "component---src-templates-about-about-js" */),
  "component---src-templates-work-featured-work-featured-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/work-featured/work-featured.js" /* webpackChunkName: "component---src-templates-work-featured-work-featured-js" */),
  "component---src-templates-home-home-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/home/home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-work-item-work-item-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/work-item/work-item.js" /* webpackChunkName: "component---src-templates-work-item-work-item-js" */),
  "component---src-templates-ideas-stories-item-ideas-stories-item-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/ideas-stories-item/ideas-stories-item.js" /* webpackChunkName: "component---src-templates-ideas-stories-item-ideas-stories-item-js" */),
  "component---src-templates-ideas-tutorials-item-ideas-tutorials-item-js": () => import("/home/ryuhei/Projects/yd-gatsby/src/templates/ideas-tutorials-item/ideas-tutorials-item.js" /* webpackChunkName: "component---src-templates-ideas-tutorials-item-ideas-tutorials-item-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/ryuhei/Projects/yd-gatsby/.cache/data.json")

