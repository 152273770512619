/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/fonts/AdelleSans/font.css'
import './src/fonts/NeueHaasUnica/font.css'
import './src/layouts/style.scss'

export const onServiceWorkerUpdateReady = () => {
    console.log(`Service worker update ready`)
}
